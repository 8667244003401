import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const preachers = {
  "galigamuwe-gnanadeepa": {
      "is_preacher": true,
      "name_en": "Galigamuwe Gnanadeepa Thero",
      "name_si": "ගලිගමුවේ ඥාණදීප හිමි",
      "alternative_names": "ගලිගමුවේ හිමි, Galigamuwe Himi",
      "picture": "galigamuwe-himi.jpg",
      "list": [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12
      ]
  },
  "baththaramulle-amadassana": {
      "is_preacher": true,
      "name_en": "Baththaramulle Amadassana Thero",
      "name_si": "බත්තරමුල්ලේ අමාදස්සන හිමි",
      "alternative_names": "බත්තරමුල්ලේ හිමි, Amadassana Himi",
      "picture": "baththaramulle-amadassana-himi.jpg",
      "list": [
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
          24,
          25,
          26,
          27,
          28,
          29,
          30,
          31,
          32
      ]
  },
  "meemure-dhammawansa": {
      "is_preacher": true,
      "name_en": "Meemure Dhammawansa Thero",
      "name_si": "මීමුරේ ධම්මවංස හිමි",
      "alternative_names": "මීමුරේ ධම්මවංශ හිමි, Meemure Dhammawansha Thero",
      "picture": "meemure-dammawansa-himi.JPEG",
      "list": [
          33,
          34,
          35,
          36,
          37,
          38,
          39,
          40,
          41,
          42,
          43,
          44
      ]
  },
  "gangodawila-soma-himi": {
      "is_preacher": true,
      "name_en": "Gangodawila Soma Himi",
      "name_si": "ගංගොඩවිල සෝම හිමි",
      "alternative_names": "ගංගොඩවිල හිමි, Gangodavila Soma Himi",
      "picture": "gangodawila-soma-himi.JPEG",
      "list": [
          45,
          46,
          47
      ]
  },
  "walasmulle-abaya-himi": {
      "is_preacher": true,
      "name_en": "Walasmulle Abhaya Thero",
      "name_si": "වලස්මුල්ලේ අභය හිමි",
      "alternative_names": "වලස්මුල්ලේ හිමි, Walasmulle Abaya Thero",
      "picture": "walasmulle-abaya-himi.JPEG",
      "list": [
          48,
          49,
          50,
          51,
          52,
          53,
          54,
          55,
          56,
          57,
          58,
          59,
          60,
          61,
          62,
          63,
          64,
          65,
          66,
          67,
          68,
          69,
          70,
          71,
          72,
          73,
          74,
          75,
          76,
          77,
          78,
          79,
          80,
          81,
          82,
          83
      ]
  },
  "rajagiriye-ariyagnana": {
      "is_preacher": true,
      "name_en": "Rajagiriye Ariyagnana Thero",
      "name_si": "රාජගිරියේ අරියඥාන හිමි",
      "alternative_names": "රාජගිරියේ හිමි, Ariyanana himi, Ariyagana himi",
      "picture": "rajagiriye-ariyagnana.JPEG",
      "list": [
          84,
          85,
          86,
          87,
          88,
          89,
          90,
          91,
          92,
          93,
          94,
          95,
          96,
          97,
          98,
          99,
          100,
          101,
          102,
          103,
          104,
          105,
          106,
          107,
          108,
          109,
          110,
          111,
          112,
          113,
          114,
          115,
          116,
          117,
          118,
          119,
          120,
          121,
          122,
          123,
          124,
          125
      ]
  },
  "rathupasketiye-vimukthirathana": {
      "is_preacher": true,
      "name_en": "Rathupasketiye Vimukthirathana Thero",
      "name_si": "රතුපස්කැටියේ විමුක්තිරතන හිමි",
      "alternative_names": "Rathupasketiye Wimukthirathana Thero, Wimuththirathana Thero",
      "picture": "rathupasketiye-vimukthirathana.JPEG",
      "list": [
          126,
          127,
          128,
          129,
          130,
          131,
          132,
          133,
          134,
          135,
          136,
          137,
          138
      ]
  },
  "hadapangoda-niwathapa": {
      "is_preacher": true,
      "name_en": "Hadapangoda Niwathapa Thero",
      "name_si": "හඳපාන්ගොඩ නිවාතාප හිමි",
      "alternative_names": "Hadapangoda Nivathapa Thero",
      "picture": "hadapangoda-niwathapa-himi.JPEG",
      "list": [
          139,
          140,
          141,
          142,
          143,
          144,
          145,
          146,
          147,
          148,
          149,
          150,
          151,
          152,
          153,
          154,
          155,
          156,
          157,
          158,
          159,
          160,
          161,
          162,
          163,
          164,
          165,
          166,
          167,
          168,
          169,
          170,
          171,
          172,
          173,
          174,
          175,
          176,
          177,
          178,
          179,
          180,
          181,
          182,
          183,
          184,
          185,
          186,
          187,
          188,
          189,
          190,
          191,
          192,
          193,
          194,
          195,
          196,
          197,
          198,
          199
      ]
  },
  "waharaka-abayarathananalankara": {
      "is_preacher": true,
      "name_en": "Waharaka Abhayarathanalankara Thero",
      "name_si": "වහරක අභයරතනාලංකාර හිමි",
      "alternative_names": "Waharaka Abayarathanalankara Thero, Waharaka Abhaya Thero",
      "picture": "waharaka-abayarathana-himi.JPEG",
      "list": [
          200,
          201,
          202,
          203,
          204,
          205,
          206,
          207,
          208,
          209,
          210,
          211,
          212,
          213,
          214,
          215,
          216,
          217,
          218,
          219,
          220,
          221,
          222,
          223,
          224,
          225,
          226,
          227,
          228,
          229,
          230,
          231,
          232,
          233,
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264
      ]
  },
  "boralle-kovida": {
      "is_preacher": true,
      "name_en": "Boralle Kovida Himi",
      "name_si": "බොරැල්ලේ කෝවිද හිමි",
      "alternative_names": "Boralle Kowida Himi, kowidha himi",
      "picture": "boralle-kovida-himi.JPEG",
      "list": [
          265,
          266
      ]
  },
  "hanwalle-medhankara": {
      "is_preacher": true,
      "name_en": "Hanwelle Medhankara Thero",
      "name_si": "හංවැල්ලේ මේධංකර හිමි",
      "alternative_names": "Hanwelle Medankara Thero",
      "picture": "hanwalle-medhankara-himi.JPEG",
      "list": [
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280
      ]
  },
  "gothatuwe-rewatha": {
      "is_preacher": true,
      "name_en": "Gothatuwe Rewatha Thero",
      "name_si": "පුජ්‍ය ගොතටුවේ රේවත හිමි",
      "alternative_names": "Gothatuwe Revatha Thero",
      "picture": "gothatuwe-rewatha-thero.JPEG",
      "list": [
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300
      ]
  },
  "mawarale-baddiya": {
      "is_preacher": true,
      "name_en": "Mawarale Baddiya Thero",
      "name_si": "මාවරලේ බද්දිය හිමි",
      "alternative_names": "භද්දිය හිමි, bhaddiya himi",
      "picture": "mawarale-baddiya-himi.JPEG",
      "list": [
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317
      ]
  },
  "thun-suthraya": {
      "is_preacher": false,
      "name_en": "Thun Suthraya",
      "name_si": "තුන් සූත්‍රය",
      "alternative_names": "තුන් සූත්‍රය, Thun Suthraya, Pirith, මහ පිරිත, Maha Piritha",
      "list": [
          318
      ]
  },
  "maha-piritha": {
      "is_preacher": false,
      "name_en": "Maha Piritha",
      "name_si": "මහ පිරිත",
      "alternative_names": "තුන් සූත්‍රය, Thun Suthraya, Pirith, මහ පිරිත, Maha Piritha",
      "list": [
          318
      ]
  },
  "seth-pirith": {
      "is_preacher": false,
      "name_en": "Seth Pirith",
      "name_si": "සෙත් පිරිත්",
      "alternative_names": "තුන් සූත්‍රය, Thun Suthraya, Pirith, මහ පිරිත, Maha Piritha",
      "list": [
          318
      ]
  }
}

const list = [
  "https://www.youtube.com/watch?v=CKtJSOJZoK8",
  "https://www.youtube.com/watch?v=s0hcWryFDx4",
  "https://www.youtube.com/watch?v=9xVhQQqccqM",
  "https://www.youtube.com/watch?v=bwWfAwHfim8",
  "https://www.youtube.com/watch?v=-c9hszmtAiE",
  "https://www.youtube.com/watch?v=IJUu6r10D6E",
  "https://www.youtube.com/watch?v=_gP1PSkC7nk",
  "https://www.youtube.com/watch?v=pyGQ-VhTmgY",
  "https://www.youtube.com/watch?v=1fPwz7vB_34",
  "https://www.youtube.com/watch?v=NsAVGNAD01w",
  "https://www.youtube.com/watch?v=STuln4u8La8",
  "https://www.youtube.com/watch?v=Kg3IMc-KBfY",
  "https://www.youtube.com/watch?v=B2ZiYTW3JI8",
  "https://www.youtube.com/watch?v=IwamO9W_QdM",
  "https://www.youtube.com/watch?v=yCs4UMR6dac",
  "https://www.youtube.com/watch?v=qnVZfiwJU1U",
  "https://www.youtube.com/watch?v=fH9XlPFidZ8",
  "https://www.youtube.com/watch?v=GNjixGhKFjo",
  "https://www.youtube.com/watch?v=Pj8cxqotYhI",
  "https://www.youtube.com/watch?v=r-PABgsHNsI",
  "https://www.youtube.com/watch?v=MaW6sjvyxuk",
  "https://www.youtube.com/watch?v=dpEdf2Mdf80",
  "https://www.youtube.com/watch?v=-u6PH01Oo_o",
  "https://www.youtube.com/watch?v=WTcbYLWJJ4g",
  "https://www.youtube.com/watch?v=xXAcF3IdvzY",
  "https://www.youtube.com/watch?v=XcmtF2lRPSE",
  "https://www.youtube.com/watch?v=tk1yKsKwKWY",
  "https://www.youtube.com/watch?v=EGbzY34cqwA",
  "https://www.youtube.com/watch?v=BfQcdN_TM4w",
  "https://www.youtube.com/watch?v=MOD9PfsDERE",
  "https://www.youtube.com/watch?v=s-IEr_VyowU",
  "https://www.youtube.com/watch?v=MeubjNup-WA",
  "https://www.youtube.com/watch?v=U8dJMOelzwQ",
  "https://www.youtube.com/watch?v=eeAqN98OIjs",
  "https://www.youtube.com/watch?v=peFggUEjaVo",
  "https://www.youtube.com/watch?v=Qp1tIgh9epg",
  "https://www.youtube.com/watch?v=prm7o3tHNfA",
  "https://www.youtube.com/watch?v=YJylVOaloPc",
  "https://www.youtube.com/watch?v=j-sEJwcQI7c",
  "https://www.youtube.com/watch?v=loBtKQ6-tSw",
  "https://www.youtube.com/watch?v=UO-JaRh7Gf8",
  "https://www.youtube.com/watch?v=FdKtfKGpFTI",
  "https://www.youtube.com/watch?v=5G8j1tZj2Jw",
  "https://www.youtube.com/watch?v=H3StXG-XD-0",
  "https://www.youtube.com/watch?v=gTXVbOD-IyY",
  "https://www.youtube.com/watch?v=8_0fw5Bh1DU",
  "https://www.youtube.com/watch?v=UN1OXWn3vbo",
  "https://www.youtube.com/watch?v=FzIML-H3Sf4",
  "https://www.youtube.com/watch?v=o6fDV2yFwRY",
  "https://www.youtube.com/watch?v=CSJ7n1MQgdU",
  "https://www.youtube.com/watch?v=_YeB8ChSLTM",
  "https://www.youtube.com/watch?v=gNMfFGL12OY",
  "https://www.youtube.com/watch?v=rV1-F5JeB08",
  "https://www.youtube.com/watch?v=jawzQj4hm08",
  "https://www.youtube.com/watch?v=L7a6JbJ581I",
  "https://www.youtube.com/watch?v=kIjnL1qnEP4",
  "https://www.youtube.com/watch?v=_V4dBZm7W2M",
  "https://www.youtube.com/watch?v=s0PF6QlUbSI",
  "https://www.youtube.com/watch?v=-CXwW-sCdi4",
  "https://www.youtube.com/watch?v=7peuaGaiyTs",
  "https://www.youtube.com/watch?v=-90SfHeUJiU",
  "https://www.youtube.com/watch?v=-skGAzkFkWk",
  "https://www.youtube.com/watch?v=uE_-3d-wvtU",
  "https://www.youtube.com/watch?v=4vY3Bb_8f2U",
  "https://www.youtube.com/watch?v=0-DuHvZabHw",
  "https://www.youtube.com/watch?v=M5nqNoqci7Q",
  "https://www.youtube.com/watch?v=uZnrFKxsZOE",
  "https://www.youtube.com/watch?v=CSmyXmRXyss",
  "https://www.youtube.com/watch?v=GGOdXZ3mtoU",
  "https://www.youtube.com/watch?v=k1GkRjdqlrg",
  "https://www.youtube.com/watch?v=Ak89wzzhZIE",
  "https://www.youtube.com/watch?v=NxWcGIZusc8",
  "https://www.youtube.com/watch?v=Is75zP1TFxw",
  "https://www.youtube.com/watch?v=4WTyeS3v3Vk",
  "https://www.youtube.com/watch?v=4ApkLz74EUo",
  "https://www.youtube.com/watch?v=P11u0aTI7NA",
  "https://www.youtube.com/watch?v=Ig0rFV6QV5s",
  "https://www.youtube.com/watch?v=Tm6PjlU2j-8",
  "https://www.youtube.com/watch?v=wAJVsiKFn5M",
  "https://www.youtube.com/watch?v=Toj3tqSRlHg",
  "https://www.youtube.com/watch?v=AMfuFy8gUaI",
  "https://www.youtube.com/watch?v=2wBzTpxfQDc",
  "https://www.youtube.com/watch?v=xZIYv4xHtDg",
  "https://www.youtube.com/watch?v=1owe_VA5Krw",
  "https://www.youtube.com/watch?v=j-19r8B75ks",
  "https://www.youtube.com/watch?v=QqtMN5zYaGM",
  "https://www.youtube.com/watch?v=GDRBHacHLUE",
  "https://www.youtube.com/watch?v=tM7g_YZ1h_o",
  "https://www.youtube.com/watch?v=Nt0It4tW2Gw",
  "https://www.youtube.com/watch?v=WWAKFMRMT84",
  "https://www.youtube.com/watch?v=tShahEeqkW8",
  "https://www.youtube.com/watch?v=l1xM8AdiLZ0",
  "https://www.youtube.com/watch?v=zL4w1Zl5XBM",
  "https://www.youtube.com/watch?v=XjZEs6u2Ano",
  "https://www.youtube.com/watch?v=XyrIzMLJcHo",
  "https://www.youtube.com/watch?v=TrHRjitTTOQ",
  "https://www.youtube.com/watch?v=-WTQYVDnx5o",
  "https://www.youtube.com/watch?v=kCHVWDV62gE",
  "https://www.youtube.com/watch?v=mVex-IQMoHw",
  "https://www.youtube.com/watch?v=95iNAKRr6-A",
  "https://www.youtube.com/watch?v=PwgjQwC12Y4",
  "https://www.youtube.com/watch?v=Y4PEGj76RVg",
  "https://www.youtube.com/watch?v=WQGp0SuErJ4",
  "https://www.youtube.com/watch?v=aSMlIWDbrZU",
  "https://www.youtube.com/watch?v=FgKtN4J7Hwo",
  "https://www.youtube.com/watch?v=9mwDejAKSYs",
  "https://www.youtube.com/watch?v=IjQMh0ry1N0",
  "https://www.youtube.com/watch?v=QDzaeVBavyQ",
  "https://www.youtube.com/watch?v=6DzeTtkt27I",
  "https://www.youtube.com/watch?v=wIfPeVjwJCQ",
  "https://www.youtube.com/watch?v=X5ziGSkDwOw",
  "https://www.youtube.com/watch?v=ObjWsUfUNTE",
  "https://www.youtube.com/watch?v=xvi3tVo-fvU",
  "https://www.youtube.com/watch?v=2bf8aroCYUg",
  "https://www.youtube.com/watch?v=CWbJEk-GhVk",
  "https://www.youtube.com/watch?v=radBAg_jr1U",
  "https://www.youtube.com/watch?v=p2cXa4UTAP4",
  "https://www.youtube.com/watch?v=dpTFtq_xqeA",
  "https://www.youtube.com/watch?v=xDYALMtL_PA",
  "https://www.youtube.com/watch?v=MH-QENtiDzM",
  "https://www.youtube.com/watch?v=i51fjF8oUyI",
  "https://www.youtube.com/watch?v=nB6cZwYt-ww",
  "https://www.youtube.com/watch?v=XdaAcJZuQpU",
  "https://www.youtube.com/watch?v=kjV6QqH1v-g",
  "https://www.youtube.com/watch?v=seUFMqL7Fp0",
  "https://www.youtube.com/watch?v=SdSqkLUYsZg",
  "https://www.youtube.com/watch?v=xv_RQ2I9N04",
  "https://www.youtube.com/watch?v=zmuDEGHLsMA",
  "https://www.youtube.com/watch?v=rzzMNLO0OZg",
  "https://www.youtube.com/watch?v=CXi-m-gxpaw",
  "https://www.youtube.com/watch?v=Mw3y0nDoQhY",
  "https://www.youtube.com/watch?v=_6T6Pjbcecs",
  "https://www.youtube.com/watch?v=2Lz1D9GZbSI",
  "https://www.youtube.com/watch?v=xeZXl_veis4",
  "https://www.youtube.com/watch?v=ckTm-le_6Eo",
  "https://www.youtube.com/watch?v=vv0dvIa05Ok",
  "https://www.youtube.com/watch?v=tDABFRfV5PE",
  "https://www.youtube.com/watch?v=zxlusgURf88",
  "https://www.youtube.com/watch?v=IDyqZLIRe4A",
  "https://www.youtube.com/watch?v=58PrBHJ5HKo",
  "https://www.youtube.com/watch?v=yTwpRyedMiI",
  "https://www.youtube.com/watch?v=fRpTUArNH0E",
  "https://www.youtube.com/watch?v=S2Q1C1fOUrY",
  "https://www.youtube.com/watch?v=4T2KJusmO94",
  "https://www.youtube.com/watch?v=ekN6BgG5TLY",
  "https://www.youtube.com/watch?v=nNuV-W_llMk",
  "https://www.youtube.com/watch?v=Og_kNs7JEn0",
  "https://www.youtube.com/watch?v=WZRbauQTwVU",
  "https://www.youtube.com/watch?v=J979dxZkgzU",
  "https://www.youtube.com/watch?v=vgUz7XGV8To",
  "https://www.youtube.com/watch?v=3pZPa4-0TIk",
  "https://www.youtube.com/watch?v=3418jWGuH4c",
  "https://www.youtube.com/watch?v=ZMC7GrFqL-Y",
  "https://www.youtube.com/watch?v=KPM5mG7xaDE",
  "https://www.youtube.com/watch?v=x8HPdgBVVSM",
  "https://www.youtube.com/watch?v=uCAgB4OPBPo",
  "https://www.youtube.com/watch?v=Q731tR3ABj8",
  "https://www.youtube.com/watch?v=fQwLY0arxWY",
  "https://www.youtube.com/watch?v=J5Tx2Zq0eLE",
  "https://www.youtube.com/watch?v=qTOR6m2TvMs",
  "https://www.youtube.com/watch?v=sqZ01SOo_E0",
  "https://www.youtube.com/watch?v=mt8i_IkAnSs",
  "https://www.youtube.com/watch?v=KHEYRnHYNeI",
  "https://www.youtube.com/watch?v=PqWU6dQKGAY",
  "https://www.youtube.com/watch?v=OQTVeoleo_A",
  "https://www.youtube.com/watch?v=AlWo35WxQ2U",
  "https://www.youtube.com/watch?v=geeAVFQ4YOQ",
  "https://www.youtube.com/watch?v=wn2mU0LyFO4",
  "https://www.youtube.com/watch?v=26K4mqTrn8I",
  "https://www.youtube.com/watch?v=aoa1ky9wQYQ",
  "https://www.youtube.com/watch?v=YI4Hs08i5WE",
  "https://www.youtube.com/watch?v=JRORuc_a3e8",
  "https://www.youtube.com/watch?v=tGUXEhu9ppg",
  "https://www.youtube.com/watch?v=Qh2pqrFA6tM",
  "https://www.youtube.com/watch?v=tXxfRSIPkUM",
  "https://www.youtube.com/watch?v=sKR2AoZ3hDc",
  "https://www.youtube.com/watch?v=ZTJRhsnmTtQ",
  "https://www.youtube.com/watch?v=Wx5mzbS0vcQ",
  "https://www.youtube.com/watch?v=lcT16JxEaZs",
  "https://www.youtube.com/watch?v=rw1aXr9xQTg",
  "https://www.youtube.com/watch?v=qTUa23RMutA",
  "https://www.youtube.com/watch?v=y00XzyB4ZYE",
  "https://www.youtube.com/watch?v=ZxM5NxeGyE4",
  "https://www.youtube.com/watch?v=kQoxsq4dHu0",
  "https://www.youtube.com/watch?v=p2JhLVJ4BTQ",
  "https://www.youtube.com/watch?v=XQYQaIk87ag",
  "https://www.youtube.com/watch?v=syw6WnWraVk",
  "https://www.youtube.com/watch?v=PLzy5tDA8XQ",
  "https://www.youtube.com/watch?v=sijKN_QIF3w",
  "https://www.youtube.com/watch?v=t5x_42KQ3JM",
  "https://www.youtube.com/watch?v=UYvKurB1k_k",
  "https://www.youtube.com/watch?v=TSrIGcret5g",
  "https://www.youtube.com/watch?v=uaVfk7HMgCo",
  "https://www.youtube.com/watch?v=mCZhBRr4DoQ",
  "https://www.youtube.com/watch?v=f_cTDQ3teoE",
  "https://www.youtube.com/watch?v=UqXU4xUYfuY",
  "https://www.youtube.com/watch?v=tYZv5yoWcSw",
  "https://www.youtube.com/watch?v=Prkp2iykRWM",
  "https://www.youtube.com/watch?v=pVLc7_82sB4",
  "https://www.youtube.com/watch?v=KadTOVxNXWw",
  "https://www.youtube.com/watch?v=c7kqmjE7wDY",
  "https://www.youtube.com/watch?v=A4T2Tm2EuQE",
  "https://www.youtube.com/watch?v=IsWl5YMD2vQ",
  "https://www.youtube.com/watch?v=sSyXckCCMAo",
  "https://www.youtube.com/watch?v=JNr5Mdzoo28",
  "https://www.youtube.com/watch?v=mEVLA7WeGoU",
  "https://www.youtube.com/watch?v=zQirT7Af2lE",
  "https://www.youtube.com/watch?v=Dlpf1AhniDM",
  "https://www.youtube.com/watch?v=ubytFpfEIxY",
  "https://www.youtube.com/watch?v=YL1Qr-QGkJ0",
  "https://www.youtube.com/watch?v=-LxdQEWP--w",
  "https://www.youtube.com/watch?v=3eaeytjv6CA",
  "https://www.youtube.com/watch?v=EPCOIRiLBfQ",
  "https://www.youtube.com/watch?v=eGCAv79j58k",
  "https://www.youtube.com/watch?v=AZgfEfP2Qjc",
  "https://www.youtube.com/watch?v=HaX3TeDKozc",
  "https://www.youtube.com/watch?v=N9PaCUov9CU",
  "https://www.youtube.com/watch?v=A3m9XSLRUoU",
  "https://www.youtube.com/watch?v=5G4-sRWY_yA",
  "https://www.youtube.com/watch?v=YiKq7K6gki8",
  "https://www.youtube.com/watch?v=uWaDCt0hq0k",
  "https://www.youtube.com/watch?v=4b8-TYojb-8",
  "https://www.youtube.com/watch?v=fHAp1U7xNmc",
  "https://www.youtube.com/watch?v=zKjgTeMf3ME",
  "https://www.youtube.com/watch?v=SGFTQmBNuzk",
  "https://www.youtube.com/watch?v=ZsuizNGjtcc",
  "https://www.youtube.com/watch?v=5pURPGRfO9s",
  "https://www.youtube.com/watch?v=uIMBZNlqbpE",
  "https://www.youtube.com/watch?v=8yfKm-zoHXo",
  "https://www.youtube.com/watch?v=mKPefqm7hNo",
  "https://www.youtube.com/watch?v=CiCgW-upRCA",
  "https://www.youtube.com/watch?v=UV9KLw2_kMM",
  "https://www.youtube.com/watch?v=TXiQPon2M-8",
  "https://www.youtube.com/watch?v=TiZ2PO-PIwg",
  "https://www.youtube.com/watch?v=HOBv0UwKOmg",
  "https://www.youtube.com/watch?v=Unkd2V8QL9U",
  "https://www.youtube.com/watch?v=jFYIoIW17q8",
  "https://www.youtube.com/watch?v=sKn1PgjMRMA",
  "https://www.youtube.com/watch?v=07l0LbVFIdA",
  "https://www.youtube.com/watch?v=cOlexVYr8es",
  "https://www.youtube.com/watch?v=v2W5r9xevHo",
  "https://www.youtube.com/watch?v=Mj_7hJlwhK8",
  "https://www.youtube.com/watch?v=F3MSuOmYisI",
  "https://www.youtube.com/watch?v=Xnwvz6JGlLI",
  "https://www.youtube.com/watch?v=ATk2yciRauY",
  "https://www.youtube.com/watch?v=5ihu4ijWZ8M",
  "https://www.youtube.com/watch?v=s8C7IHO4m84",
  "https://www.youtube.com/watch?v=UBWEJQJRzoo",
  "https://www.youtube.com/watch?v=L7TdyyK7M6s",
  "https://www.youtube.com/watch?v=EuFqtpa8H4s",
  "https://www.youtube.com/watch?v=I1RNUY3Bouc",
  "https://www.youtube.com/watch?v=ptbW6ZOW3ws",
  "https://www.youtube.com/watch?v=5XEjwut41v8",
  "https://www.youtube.com/watch?v=xMNB-eiJ4CY",
  "https://www.youtube.com/watch?v=fFEk6nuaAyY",
  "https://www.youtube.com/watch?v=EqTo2xXW7jQ",
  "https://www.youtube.com/watch?v=i8AM01mc_1Q",
  "https://www.youtube.com/watch?v=k63BiPAoXgw",
  "https://www.youtube.com/watch?v=ysbxH7su4l8",
  "https://www.youtube.com/watch?v=ub199xe6Fn8",
  "https://www.youtube.com/watch?v=b9PoyAEi3oM",
  "https://www.youtube.com/watch?v=EMWjfq4VFfM",
  "https://www.youtube.com/watch?v=LNRJxmNFC8Y",
  "https://www.youtube.com/watch?v=dV_dnhJErTk",
  "https://www.youtube.com/watch?v=EDzg7D7lxeQ",
  "https://www.youtube.com/watch?v=649ZZKto0b8",
  "https://www.youtube.com/watch?v=iMK6Mj7NrHU",
  "https://www.youtube.com/watch?v=UGhB14H8QNA",
  "https://www.youtube.com/watch?v=eyki2TT3snU",
  "https://www.youtube.com/watch?v=Z8QekZlz--g",
  "https://www.youtube.com/watch?v=HZfxuJBGud4",
  "https://www.youtube.com/watch?v=j3KxSdvYt3g",
  "https://www.youtube.com/watch?v=cp2PQ6o-lgo",
  "https://www.youtube.com/watch?v=c1n56TK0YSU",
  "https://www.youtube.com/watch?v=QGnGb3JcVls",
  "https://www.youtube.com/watch?v=OQDpvqXb370",
  "https://www.youtube.com/watch?v=l-damV1JTKI",
  "https://www.youtube.com/watch?v=iFos6Qmyag0",
  "https://www.youtube.com/watch?v=VV5bycq4wrs",
  "https://www.youtube.com/watch?v=hIqvTcmUtkA",
  "https://www.youtube.com/watch?v=46g2jlNbm8c",
  "https://www.youtube.com/watch?v=8L108jf_7Gk",
  "https://www.youtube.com/watch?v=RGxgtYYa6LA",
  "https://www.youtube.com/watch?v=7LWPGoBAkbk",
  "https://www.youtube.com/watch?v=7SidOsD8j6Q",
  "https://www.youtube.com/watch?v=NbZqyADch9A",
  "https://www.youtube.com/watch?v=Zq8MriHrowc",
  "https://www.youtube.com/watch?v=vNrN1oIzNMU",
  "https://www.youtube.com/watch?v=FxysIJEkaZA",
  "https://www.youtube.com/watch?v=zhKy3-1A1K0",
  "https://www.youtube.com/watch?v=eRAiaB6iatM",
  "https://www.youtube.com/watch?v=AGeDUIHwt58",
  "https://www.youtube.com/watch?v=VaVhv78rJ2Y",
  "https://www.youtube.com/watch?v=39ulIYLQyfY",
  "https://www.youtube.com/watch?v=UeoVxAMOarI",
  "https://www.youtube.com/watch?v=In4cRN-dsCk",
  "https://www.youtube.com/watch?v=ehF0owCeYnI",
  "https://www.youtube.com/watch?v=Jz8ZS21_Mr8",
  "https://www.youtube.com/watch?v=ONwUYJ3WPFc",
  "https://www.youtube.com/watch?v=V8ocTAdUoDI",
  "https://www.youtube.com/watch?v=elmXyE-RZZY",
  "https://www.youtube.com/watch?v=QJrnBaVkXrQ",
  "https://www.youtube.com/watch?v=AVsRlyUesn4",
  "https://www.youtube.com/watch?v=vXkT8oLuLjQ",
  "https://www.youtube.com/watch?v=kzBpROfkfYs",
  "https://www.youtube.com/watch?v=C-V1fNsaY_s",
  "https://www.youtube.com/watch?v=OUXldJYxgow",
  "https://www.youtube.com/watch?v=_ZiCZWke5mo",
  "https://www.youtube.com/watch?v=jIFp5ezfmSQ",
  "https://www.youtube.com/watch?v=IhpaTBXDSDA",
  "https://www.youtube.com/watch?v=bA0mn0zvCbo",
  "https://www.youtube.com/watch?v=l_N_aW9MM2Y",
  "https://www.youtube.com/watch?v=IW2B_VLH7o4",
  "https://www.youtube.com/watch?v=Xxw2edvYWng",
  "https://www.youtube.com/watch?v=a21qsi1g8cc",
  "https://www.youtube.com/watch?v=RGBPJ82LEbA",
  "https://www.youtube.com/watch?v=VjupWRnTbRk",
  "https://www.youtube.com/watch?v=C4hap1nOmRo",
  "https://www.youtube.com/watch?v=0_sz5r1Y86c"
]

function KeyPage() {
  const { key } = useParams();
  const [values, setValues] = useState(preachers[key].list.map((index) => list[index]));
  const [error, setError] = useState(null);
  const [name_en, setNameEN] = useState(preachers[key].name_en);
  const [name_si, setNameSI] = useState(preachers[key].name_si);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const completeListResponse = await fetch("/complete_list.json");
  //       const mapperResponse = await fetch("/preachers.json");

  //       if (!completeListResponse.ok || !mapperResponse.ok) {
  //         throw new Error("Failed to fetch JSON files");
  //       }

  //       const completeList = await completeListResponse.json();
  //       const mapper = await mapperResponse.json();

  //       if (mapper[key]) {
  //         const mappedValues = mapper[key].list.map((index) => completeList[index]);
  //         setValues(mappedValues);
  //         setNameEN(mapper[key].name_en)
  //         setNameSI(mapper[key].name_si)
  //       } else {
  //         setValues([]);
  //       }
  //     } catch (error) {
  //       setError(error.message);
  //     }
  //   };

  //   fetchData();
  // }, [key]);

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <div>
      <h1 style={{fontFamily:"N-Abhaya-bold-2019", color:"#fcdf03", margin : '2vw', fontSize:'min(6vw, 50px)'}}>{name_si? name_si + " - " + name_en : "Not Found" }</h1>
      {values.length > 0 ? (
        <div style={styles.gridContainer}>
          {values.map((value, index) => (
            <div style={styles.card}>
              <iframe key={index} style={styles.iframe} 
              src={"https://www.youtube.com/embed/" + value.split("=").reverse()[0] + "?si=Sl0fNUT60lFLIhTu"} 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>

              </iframe>
            </div>
          ))}
        </div>
      ) : (
        <p>Sorry No results.</p>
      )}
    </div>
  );
}

// CSS-in-JS styles for simplicity
const styles = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(max(300px,20vw), 1fr))', // Minimum column size of 400px
    gap: '20px', // Space between cards
    padding: '20px',
    margin: '0 auto', // Center the grid container
  },
  card: {
    width: '100%', // Card takes up the full column width
    maxWidth: '500px', // Optional: Limit the maximum card width
    minWidth: '300px', // Minimum card width matches iframe
    aspectRatio: '16 / 9', // Maintain a 16:9 aspect ratio for each card
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
  },
  iframe: {
    width: '100%', // Fill the card width
    height: '100%', // Automatically adjust height based on aspect ratio
    border: 'none',
    display: 'block',
  },
};


export default KeyPage;
