import React, { useState, useEffect, useRef, useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export default function Stars() {

    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => { }, []);


    return (
        <div style={{ position: "relative", width: "100%" }}>

            {/* Particles Effect */}
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    autoPlay: true,
                    fullScreen:false,
                    background: { color: { value: "#111111" } },
                    detectRetina: true,
                    fpsLimit: 60,
                    interactivity: {
                        events: { resize: false },
                    },
                    particles: {
                        color: { value: "#FFD700" }, // Gold particles
                        move: { enable: true, speed: 2, direction: "none" },
                        number: { value: 1000 - window.innerWidth/2, density: { enable: true, area: 800 } },
                        opacity: {
                            value: 0.8,
                            animation: { enable: true, speed: 0.5, minimumValue: 0.3 },
                        },
                        size: {
                            value: 3,
                            random: { enable: true, minimumValue: 1 },
                            animation: { enable: true, speed: 3, minimumValue: 1 },
                        },
                    },
                }}
            />
        </div>
    );
}
