import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import KeyPage from "./KeyPage";
import HomePage from "./HomePage";
import Stars from './particles';
import { FaYoutube, FaFacebook, FaTiktok } from 'react-icons/fa';

function App() {
  useEffect(() => {


    // Optionally, you can add JavaScript control for transitions if necessary
    const image2 = document.querySelector('.image2');
    const image3 = document.querySelector('.image3');
    const image4 = document.querySelector('.image4');

    // Fade-in the images one by one using JavaScript if needed
    setTimeout(() => {
      image2.style.opacity = 1;  // Fade image2 in after animation
    }, 500); // Fade in after image2 slide completes

    setTimeout(() => {
      image3.style.opacity = 1;  // Fade image3 in after animation
      image4.style.opacity = 1;
    }, 2000); // Fade in after image3 slide completes
  }, []);

  return (
    <div>
      <div className="inner-container">
        <Stars />
        <img src="/images/image2.png" alt="Image 2" className="image2" />
        <img src="/images/image3.png" alt="Image 3" className="image3" />
        <img src="/images/image4.png" alt="Image 4" className="image4" />
      </div>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:key" element={<KeyPage />} />
        </Routes>
      </Router>

      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px', padding : '20px', background:'#333' }}>
            {/* YouTube Button */}
            <a
                href="https://www.youtube.com/channel/UCTirsoDgGvqiWsz9XaJU14g"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'red', fontSize: '2rem' }}
            >
                <FaYoutube />
            </a>

            {/* Facebook Button */}
            <a
                href="https://www.facebook.com/youturnyoutube"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#4267B2', fontSize: '2rem' }}
            >
                <FaFacebook />
            </a>

            {/* TikTok Button */}
            <a
                href="https://www.tiktok.com/@youturnyoutube"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'black', fontSize: '2rem' }}
            >
                <FaTiktok />
            </a>
        </div>

    </div>
  );
}

export default App;