import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const preachers = {
  "galigamuwe-gnanadeepa": {
      "is_preacher": true,
      "name_en": "Galigamuwe Gnanadeepa Thero",
      "name_si": "ගලිගමුවේ ඥාණදීප හිමි",
      "alternative_names": "ගලිගමුවේ හිමි, Galigamuwe Himi",
      "picture": "galigamuwe-himi.jpg",
      "list": [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12
      ]
  },
  "baththaramulle-amadassana": {
      "is_preacher": true,
      "name_en": "Baththaramulle Amadassana Thero",
      "name_si": "බත්තරමුල්ලේ අමාදස්සන හිමි",
      "alternative_names": "බත්තරමුල්ලේ හිමි, Amadassana Himi",
      "picture": "baththaramulle-amadassana-himi.jpg",
      "list": [
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23,
          24,
          25,
          26,
          27,
          28,
          29,
          30,
          31,
          32
      ]
  },
  "meemure-dhammawansa": {
      "is_preacher": true,
      "name_en": "Meemure Dhammawansa Thero",
      "name_si": "මීමුරේ ධම්මවංස හිමි",
      "alternative_names": "මීමුරේ ධම්මවංශ හිමි, Meemure Dhammawansha Thero",
      "picture": "meemure-dammawansa-himi.JPEG",
      "list": [
          33,
          34,
          35,
          36,
          37,
          38,
          39,
          40,
          41,
          42,
          43,
          44
      ]
  },
  "gangodawila-soma-himi": {
      "is_preacher": true,
      "name_en": "Gangodawila Soma Himi",
      "name_si": "ගංගොඩවිල සෝම හිමි",
      "alternative_names": "ගංගොඩවිල හිමි, Gangodavila Soma Himi",
      "picture": "gangodawila-soma-himi.JPEG",
      "list": [
          45,
          46,
          47
      ]
  },
  "walasmulle-abaya-himi": {
      "is_preacher": true,
      "name_en": "Walasmulle Abhaya Thero",
      "name_si": "වලස්මුල්ලේ අභය හිමි",
      "alternative_names": "වලස්මුල්ලේ හිමි, Walasmulle Abaya Thero",
      "picture": "walasmulle-abaya-himi.JPEG",
      "list": [
          48,
          49,
          50,
          51,
          52,
          53,
          54,
          55,
          56,
          57,
          58,
          59,
          60,
          61,
          62,
          63,
          64,
          65,
          66,
          67,
          68,
          69,
          70,
          71,
          72,
          73,
          74,
          75,
          76,
          77,
          78,
          79,
          80,
          81,
          82,
          83
      ]
  },
  "rajagiriye-ariyagnana": {
      "is_preacher": true,
      "name_en": "Rajagiriye Ariyagnana Thero",
      "name_si": "රාජගිරියේ අරියඥාන හිමි",
      "alternative_names": "රාජගිරියේ හිමි, Ariyanana himi, Ariyagana himi",
      "picture": "rajagiriye-ariyagnana.JPEG",
      "list": [
          84,
          85,
          86,
          87,
          88,
          89,
          90,
          91,
          92,
          93,
          94,
          95,
          96,
          97,
          98,
          99,
          100,
          101,
          102,
          103,
          104,
          105,
          106,
          107,
          108,
          109,
          110,
          111,
          112,
          113,
          114,
          115,
          116,
          117,
          118,
          119,
          120,
          121,
          122,
          123,
          124,
          125
      ]
  },
  "rathupasketiye-vimukthirathana": {
      "is_preacher": true,
      "name_en": "Rathupasketiye Vimukthirathana Thero",
      "name_si": "රතුපස්කැටියේ විමුක්තිරතන හිමි",
      "alternative_names": "Rathupasketiye Wimukthirathana Thero, Wimuththirathana Thero",
      "picture": "rathupasketiye-vimukthirathana.JPEG",
      "list": [
          126,
          127,
          128,
          129,
          130,
          131,
          132,
          133,
          134,
          135,
          136,
          137,
          138
      ]
  },
  "hadapangoda-niwathapa": {
      "is_preacher": true,
      "name_en": "Hadapangoda Niwathapa Thero",
      "name_si": "හඳපාන්ගොඩ නිවාතාප හිමි",
      "alternative_names": "Hadapangoda Nivathapa Thero",
      "picture": "hadapangoda-niwathapa-himi.JPEG",
      "list": [
          139,
          140,
          141,
          142,
          143,
          144,
          145,
          146,
          147,
          148,
          149,
          150,
          151,
          152,
          153,
          154,
          155,
          156,
          157,
          158,
          159,
          160,
          161,
          162,
          163,
          164,
          165,
          166,
          167,
          168,
          169,
          170,
          171,
          172,
          173,
          174,
          175,
          176,
          177,
          178,
          179,
          180,
          181,
          182,
          183,
          184,
          185,
          186,
          187,
          188,
          189,
          190,
          191,
          192,
          193,
          194,
          195,
          196,
          197,
          198,
          199
      ]
  },
  "waharaka-abayarathananalankara": {
      "is_preacher": true,
      "name_en": "Waharaka Abhayarathanalankara Thero",
      "name_si": "වහරක අභයරතනාලංකාර හිමි",
      "alternative_names": "Waharaka Abayarathanalankara Thero, Waharaka Abhaya Thero",
      "picture": "waharaka-abayarathana-himi.JPEG",
      "list": [
          200,
          201,
          202,
          203,
          204,
          205,
          206,
          207,
          208,
          209,
          210,
          211,
          212,
          213,
          214,
          215,
          216,
          217,
          218,
          219,
          220,
          221,
          222,
          223,
          224,
          225,
          226,
          227,
          228,
          229,
          230,
          231,
          232,
          233,
          234,
          235,
          236,
          237,
          238,
          239,
          240,
          241,
          242,
          243,
          244,
          245,
          246,
          247,
          248,
          249,
          250,
          251,
          252,
          253,
          254,
          255,
          256,
          257,
          258,
          259,
          260,
          261,
          262,
          263,
          264
      ]
  },
  "boralle-kovida": {
      "is_preacher": true,
      "name_en": "Boralle Kovida Himi",
      "name_si": "බොරැල්ලේ කෝවිද හිමි",
      "alternative_names": "Boralle Kowida Himi, kowidha himi",
      "picture": "boralle-kovida-himi.JPEG",
      "list": [
          265,
          266
      ]
  },
  "hanwalle-medhankara": {
      "is_preacher": true,
      "name_en": "Hanwelle Medhankara Thero",
      "name_si": "හංවැල්ලේ මේධංකර හිමි",
      "alternative_names": "Hanwelle Medankara Thero",
      "picture": "hanwalle-medhankara-himi.JPEG",
      "list": [
          267,
          268,
          269,
          270,
          271,
          272,
          273,
          274,
          275,
          276,
          277,
          278,
          279,
          280
      ]
  },
  "gothatuwe-rewatha": {
      "is_preacher": true,
      "name_en": "Gothatuwe Rewatha Thero",
      "name_si": "පුජ්‍ය ගොතටුවේ රේවත හිමි",
      "alternative_names": "Gothatuwe Revatha Thero",
      "picture": "gothatuwe-rewatha-thero.JPEG",
      "list": [
          281,
          282,
          283,
          284,
          285,
          286,
          287,
          288,
          289,
          290,
          291,
          292,
          293,
          294,
          295,
          296,
          297,
          298,
          299,
          300
      ]
  },
  "mawarale-baddiya": {
      "is_preacher": true,
      "name_en": "Mawarale Baddiya Thero",
      "name_si": "මාවරලේ බද්දිය හිමි",
      "alternative_names": "භද්දිය හිමි, bhaddiya himi",
      "picture": "mawarale-baddiya-himi.JPEG",
      "list": [
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          310,
          311,
          312,
          313,
          314,
          315,
          316,
          317
      ]
  },
  "thun-suthraya": {
      "is_preacher": false,
      "name_en": "Thun Suthraya",
      "name_si": "තුන් සූත්‍රය",
      "alternative_names": "තුන් සූත්‍රය, Thun Suthraya, Pirith, මහ පිරිත, Maha Piritha",
      "list": [
          318
      ]
  },
  "maha-piritha": {
      "is_preacher": false,
      "name_en": "Maha Piritha",
      "name_si": "මහ පිරිත",
      "alternative_names": "තුන් සූත්‍රය, Thun Suthraya, Pirith, මහ පිරිත, Maha Piritha",
      "list": [
          318
      ]
  },
  "seth-pirith": {
      "is_preacher": false,
      "name_en": "Seth Pirith",
      "name_si": "සෙත් පිරිත්",
      "alternative_names": "තුන් සූත්‍රය, Thun Suthraya, Pirith, මහ පිරිත, Maha Piritha",
      "list": [
          318
      ]
  }
}

function HomePage() {
  const [keys, setKeys] = useState(Object.keys(preachers));
  const [error, setError] = useState(null);
  const [mapperData, setMapperdata] = useState(preachers)

  // useEffect(() => {
  //   const fetchKeys = async () => {
  //     try {
  //       const response = await fetch("/preachers.json");

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch JSON file");
  //       }

  //       const mapper = await response.json();
  //       setMapperdata(mapper)
  //       setKeys(Object.keys(mapper));
  //     } catch (error) {
  //       setError(error.message);
  //     }
  //   };

  //   fetchKeys();
  // }, []);

  if (error) {
    //return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1 style={{ fontFamily: 'UN-Gurulugomi', color: "#ede609", textAlign: "center", fontSize: "5vw" }}>
        "දසබලසේල පභවා නිබ්බානමහා සමුද්ද පරියන්තා අට්ඨංග මග්ගස්සලිලා ජිනවචන තදි චිරං වහභුති"
      </h1>
      <h2 style={{ fontFamily: 'UN-Derana', color: "#ede609", textAlign: "center", fontSize: "4vw", padding: ('0vw', '5vw', '0vw', '5vw') }}>
        දසබලයන් වහන්සේ නැමැති ශෛලමය පර්වතයෙන් පැන නැගි අමාමහ නිවන නම්වු මහා සාගරයෙන් අවසන් කොට ඇති අර්ය අශ්ටාංගික මාර්ගය නම්වු සිසිල් දිය දහරින් හෙබි උතුම් ශ්‍රි බුද්ධ වචන ගංගාව ලො සතුන්ගේ සසර දුක නිවාලමින් බොහො කල් ගලා බස්නා සේක්වා !!
      </h2>
      <h1 style={{ fontFamily: 'UN-Arundathee', color: "#ede609", textAlign: "center", fontSize: "4vw" }}>ස්වාමීන් වහන්සේලාගෙන් දේශනා සොයන්න</h1>
      {keys.length > 0 ? (
        <div style={styles.gridContainer}>
          {keys.map((key) => {
            const item = mapperData[key];
            
            return (
              item.is_preacher?
              <Link
                to={`/${key}`}
                key={key}
                style={styles.card}
              >
                <div key={key} style={styles.card}>
                  <img
                    src={'images/thero/' + item.picture}
                    alt={item.name_en}
                    style={styles.image}
                  />
                  <div style={styles.cardContent}>
                    <h3 style={styles.nameSi}>{item.name_si}</h3>
                    <p style={styles.nameEn}>{item.name_en}</p>
                  </div>
                </div>
              </Link> : null
            );
          })}
        </div>
      ) : (
        <p>No keys available.</p>
      )}
    </div>
  );
}

// CSS-in-JS styles for simplicity
const styles = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min(200px, 40%), 1fr))',
    gap: '20px',
    padding: '20px',
    maxWidth: '1200px', // Ensure 5 columns max at 200px each
    margin: '0 auto', // Center the grid container
  },
  card: {
    textDecoration: 'none', // Prevent text underline
    color: 'inherit', // Keep text color consistent
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)'
    },
  },
  cardContent: {
    padding: '16px',
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: '250px',
    objectFit: 'cover',
  },
  nameSi: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '8px 0 4px',
  },
  nameEn: {
    fontSize: '14px',
    color: '#666',
    margin: '0 0 12px',
  },
  link: {
    textDecoration: 'none',
    color: '#007BFF',
    fontWeight: 'bold',
    fontSize: '14px',
  },
};

export default HomePage;
